@import '../../../_variables';

#purchase-pipeline-list {
  .headline {
    font-family: 'Stolzl-Bold';
    margin-bottom: 10px;
    font-size: $text-large;
  }
  .list {
    background-color: $white;
    padding: 15px;
    border-radius: $default-border-radius;
    .item {
      display: flex;
      border-bottom: thin solid $red;
      padding: 10px 0px;
      .name,
      .date,
      .status,
      .detail-wrapper {
        flex: 1 1 0px;
      }
      .detail-wrapper {
        cursor: pointer;
        text-align: right;
        text-transform: uppercase;
      }
      &:last-child {
        border-bottom: none;
      }
    }
  }
  .message {
    margin: 15px 0px;
    text-align: center;
  }
  @media (max-width: $breakpoint) {
    .list {
      .item {
        display: block;
        .detail-wrapper {
          text-align: center;
          margin-top: 10px;
        }
      }
    }
  }
}
