@import '../../../_variables';

.custom-MUI-radiogroup {
  .MuiFormLabel-root {
    padding: 0px;
    margin: 0px;
    margin-bottom: 10px;
    font-family: 'Solzl-Book', sans-serif;
    font-size: 15px;
    color: $black;
    &.Mui-focused {
      color: $black;
    }
  }

  .MuiRadio-root {
    svg * {
      color: $red;
    }
  }

  @media (max-width: $breakpoint) {
  }
}
