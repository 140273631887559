@import '../../../../_variables';

.entry-wrapper {
  margin: 15px 0px;
  .header {
    padding: 10px;
    .title {
      font-size: $text-x-large;
      font-family: 'Stolzl-Bold';
      text-align: center;
      color: $red;
      text-transform: uppercase;
    }
    .subtitle {
      font-size: $text-medium;
      text-align: center;
      color: $red;
    }
  }
  .body {
    border-radius: 15px;
    padding: 20px;
    background-color: white;
  }
  @media (max-width: $breakpoint) {
    margin-top: 0px;
  }
}
