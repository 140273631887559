$black: #4b4b4d;
$white: #ffffff;
$blue: rgb(21, 44, 87);
$lightblue: rgb(136, 149, 170);
$gray: rgb(217, 217, 217);
$red: rgb(142, 28, 33);
$green: #21b692;
$gold: #c9b877;
$silver: rgb(221, 226, 223);
$platinum: rgb(191, 197, 199);
$palladium: rgb(152, 159, 158);
$gold-card: rgb(235, 222, 128);

$bg: $gold;
$bg-gray: rgb(218, 213, 214);

$color-main: $red;

$text-x-small: 11px;
$text-small: 13px;
$text-medium: 15px;
$text-large: 18px;
$text-x-large: 21px;

$font-size-menu: $text-large;

$default-border-radius: 5px;

$default-box-shadow: 0px 5px 8px -1px rgba(21, 44, 87, 0.39);
// $default-box-shadow: 0 0 24px #ddd;

$default-zoom-in-scale: scale(1.005);

$default-transition-speed: 0.2s;

$breakpoint: 992px;
