@import '../../../../_variables';

.entry-offer {
  .download-wrapper,
  .iban-wrapper,
  .reply-cta-wrapper {
    text-align: center;
  }
  .selection-wrapper .MuiFormGroup-root {
    justify-content: center;
  }
  .iban-wrapper {
    input {
      border-radius: 30px;
      padding: 5px 10px;
      background-color: $white;
      text-align: center;
      border: thin solid $gray;
      outline: none;
      width: 50%;
    }
  }
  .reply-cta-wrapper {
    margin-top: 15px;
  }
  .label {
    text-align: center;
    color: $red;
  }
  @media (max-width: $breakpoint) {
    .iban-wrapper {
      input {
        width: 100%;
      }
    }
  }
}
