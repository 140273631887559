@import '../../../../_variables';

.entry-shipment {
  .label {
    color: $red;
    text-align: center;
  }
  .shipment-label-download-wrapper {
    text-align: center;
    margin: 15px 0px;
  }
  .methods {
    display: flex;
    gap: 15px;
    .method {
      cursor: pointer;
      flex: 1 1 0px;
      background-color: $gold;
      border-radius: $default-border-radius;
      padding: 30px;
      .name {
        font-family: 'Stolzl-Bold';
        font-size: $text-large;
      }
      .name,
      .description {
        color: $red;
        text-align: center;
      }
      &.selected {
        background-color: $gold;
        background-color: $red;
        .name,
        .description {
          color: $white;
        }
      }
    }
  }
  .confirm-cta {
    text-align: center;
    margin-top: 15px;
  }
  .download-shiping-label,
  .download-cover-label {
    margin: 15px 0px;
    text-align: center;
  }
  .hint {
    margin-top: 15px;
    text-align: center;
    font-family: 'Stolzl-Bold';
    color: $red;
  }
  @media (max-width: $breakpoint) {
    .methods {
      display: block;
      .method {
        margin-top: 10px;
        &:first-child {
          margin-top: 0px;
        }
      }
    }
  }
}
