@import '../../../../../../_variables';

.admin-entry-payment {
  width: 100%;
  .title {
    margin-bottom: 5px;
    font-size: $text-large;
  }
  .label {
    font-weight: bold;
  }
  .payment-fullfilled {
    display: inline-block;
  }
  .iban {
    font-family: 'Stolzl-Bold';
    margin: 15px 0px;
    font-size: $text-large;
  }
  .error {
    margin-top: 15px;
  }
}
