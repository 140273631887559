@import '../../../../../../_variables';

.admin-entry-start {
  width: 100%;
  .title {
    margin-bottom: 5px;
    font-size: $text-large;
  }
  .label {
    font-weight: bold;
  }
  .metal {
    display: flex;
    .key {
      width: 20%;
      font-family: 'Stolzl-Bold';
    }
    .value {
      width: 80%;
    }
  }
  .personal-data {
    margin-top: 15px;
    .entry {
      display: flex;
      .key {
        width: 20%;
        font-family: 'Stolzl-Bold';
      }
      .value {
        width: 80%;
      }
    }
  }
  .hint {
    margin-top: 15px;
  }
  @media (max-width: $breakpoint) {
    .metal {
      display: block;
      .key,
      .value {
        width: 100%;
      }
    }
    .personal-data {
      .entry {
        display: block;
        .key,
        .value {
          width: 100%;
        }
      }
    }
  }
}
