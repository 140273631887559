@import '../../../../_variables';

.entry-start {
  width: 100%;
  .label {
    font-weight: bold;
  }
  .metal {
    margin-top: 15px;
    display: flex;
    .key {
      width: 30%;
      font-family: 'Stolzl-Bold';
    }
    .amount {
      width: 20%;
    }
    .price {
      width: 20%;
    }
    .hint {
      width: 30%;
      text-align: right;
    }
    .key,
    .amount,
    .price,
    .hint {
      color: $red;
    }
    &:first-child {
      margin-top: 0px;
    }
  }
  .bottom-hint {
    margin-top: 15px;
    color: $red;
    text-align: center;
  }
  @media (max-width: $breakpoint) {
    .metal {
      display: block;
      .key,
      .amount,
      .price,
      .hint {
        width: 100%;
        text-align: left;
      }
    }
  }
}
