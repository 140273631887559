@import '../../../../../_variables';

.unpacking-video {
  background-color: $white;
  padding: 15px;
  border-radius: $default-border-radius;
  margin-bottom: 15px;
  .upload-wrapper {
    display: inline-block;
  }
}
