@import '../../../_variables';

.custom-MUI-button {
  &.MuiButton-root {
    background-color: $red;
    padding: 7.5px 10px;
    color: $white !important;
    &:hover {
      background-color: $red;
    }
  }

  @media (max-width: $breakpoint) {
  }
}
