@import '../../../_variables';

.custom-MUI-dropdown {
  &.MuiFormControl-root {
    .MuiFilledInput-root:after {
      border-color: $red;
    }
    .MuiInputLabel-shrink {
      color: $red;
    }
  }
}
