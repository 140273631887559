@import '../../../__variables.scss';

#footer {
  background-color: $white;
  border-top: 2px solid $red;
  .copyright {
    padding: 20px 0px;
    .container {
      font-size: $text-small;
      display: flex;
      .logo img {
        height: 25px;
      }
      div {
        flex: 1 1 0px;
        text-align: center;
        color: $black;
        a {
          color: $black;
        }
      }
    }
  }
}

@media (max-width: $breakpoint) {
  #footer {
    .copyright {
      text-align: center !important;
      .container {
        display: block;
        div {
          margin-bottom: 10px;
        }
      }
    }
  }
}
