@import '../../__variables.scss';

#home {
  .content-wrapper {
    min-height: 150vh;
    background-image: url('../../assets/image/bg.jpg');
    background-size: cover;
    padding-bottom: 100px;
    .card-content {
      margin-top: 150px;
      background-color: rgba(255, 255, 255, 0.4);
      padding: 20px 50px;
      border: 20px solid $red;
      border-radius: 20px;
    }
    .message {
      text-align: center;
      margin: 15px 0px;
      font-size: $text-large;
      color: $white;
    }
  }
  @media (max-width: $breakpoint) {
    .content-wrapper {
      .card-content {
        margin-top: 0px;
        padding: 15px;
        border: 10px solid $red;
      }
    }
  }
}
