@import '../../../../../../_variables';

.admin-entry-offer {
  width: 100%;
  .title {
    margin-bottom: 5px;
    font-size: $text-large;
  }
  .label {
    font-weight: bold;
    &.strong {
      font-family: 'Stolzl-Bold';
    }
  }
  .download-cta-wrapper {
    display: inline-block;
  }
  .error {
    margin-top: 15px;
  }
}
