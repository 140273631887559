@import '../../../../_variables';

#purchase-pipeline-detail {
  .headline {
    font-family: 'Stolzl-Bold';
    margin-bottom: 10px;
    font-size: $text-large;
  }
  .entries {
    background-color: $white;
    padding: 15px;
    border-radius: $default-border-radius;
    display: flex;
    flex-direction: column-reverse;
    .item {
      padding: 15px 0px;
      display: flex;
      border-top: thin solid $red;
      .name {
        width: 50%;
      }
      .status {
        width: 40%;
      }
      .detail-wrapper {
        width: 10%;
        text-align: center;
        cursor: pointer;
      }
      &:last-child {
        border-top: none;
      }
    }
  }
  .message {
    margin: 15px 0px;
    text-align: center;
  }
}
