@import '../../../__variables.scss';

#admin-layout {
  display: flex;
  #header {
    width: 100%;
    height: 80px;
    position: fixed;
    top: 0px;
    left: 0px;
    background-color: $red;
    padding: 10px 15px;
    z-index: 1;
    display: flex;
    align-items: center;
    .logo {
      width: 30%;
      text-align: left;
      img {
        height: 60px;
        display: inline-block;
      }
      .logo-label {
        display: inline-block;
        text-transform: uppercase;
        font-family: 'Stolzl-Bold';
        color: $white;
        margin-top: 5px;
      }
    }
    .navigation {
      width: 70%;
      .item {
        display: inline-block;
        .logout,
        a {
          color: $white;
          padding: 5px;
        }
      }
    }
  }
  #admin-content-wrapper {
    width: 100%;
    min-height: 100vh;
    background-color: $bg;
    position: relative;
    padding: 20px;
    padding-top: 100px;
    #admin-content {
      position: relative;
      min-height: 100%;
    }
    &.card {
      max-height: 100vh;
      overflow: hidden;
      #admin-content {
        max-height: 100%;
        overflow: scroll;
        border-radius: $default-border-radius;
        box-shadow: $default-box-shadow;
        background-color: $white;
      }
    }
  }
  @media (max-width: $breakpoint) {
    #header {
      .logo {
        width: 100%;
      }
      .mobile-nav-toggle {
        svg {
          font-size: 40px;
          * {
            color: $white;
          }
        }
      }
      .navigation {
        &.open {
          display: block;
        }
        display: none;
        position: fixed;
        top: 80px;
        left: 0px;
        width: 100%;
        background-color: $red;
        .item {
          display: block;
          border-top: thin solid $white;
          padding: 10px 0px;
          &:first-child {
            border-top: none;
          }
        }
      }
    }
  }
}
