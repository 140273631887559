@import '../../../../_variables';

.entry-completed {
  .label {
    text-align: center;
    color: $red;
    margin-top: 15px;
  }
  .icon {
    margin: 0px auto;
    width: 50px;
    height: 50px;
    background-color: $red;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      font-size: 30px;
      * {
        color: $gold;
      }
    }
  }
}
