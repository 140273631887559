@import '../../../_variables';

#metal-calculator-data-loading {
  .label {
    text-align: center;
    font-size: 18px;
    color: $white;
    margin-top: 15px;
  }
  .loader {
    text-align: center;
  }
}

#metal-calculator {
  position: relative;
  .type-selector {
    margin-bottom: 30px;
    position: absolute;
    width: 100%;
    top: -375px;
    .label {
      margin-top: 15px;
      margin-bottom: 15px;
      text-align: center;
      color: $white;
    }
    .list {
      display: flex;
      gap: 10px;
      margin: 0px 15%;
      .item {
        width: 33%;
        height: 250px;
        position: relative;
        text-align: center;
        border-radius: 30px;
        cursor: pointer;
        &.gold {
          background-color: $gold-card;
        }
        &.silver {
          background-color: $silver;
        }
        &.platinum {
          background-color: $platinum;
        }
        &.palladium {
          background-color: $palladium;
        }
        .overlay {
          position: absolute;
          height: 100%;
          width: 100%;
          background-position: top right;
          background-size: 100% 100%;
          border-radius: 30px;
          overflow: hidden;
          z-index: 1;
          opacity: 0.5;
        }
        .name {
          font-size: 26px;
          color: $red;
          text-align: center;
          text-transform: uppercase;
          position: absolute;
          top: 25px;
          width: 100%;
          text-align: center;
        }
        .stamp {
          font-weight: bold;
          font-size: 80px;
          text-shadow: -1.5px -1.5px 0px rgba(0, 0, 0, 0.5);
          position: absolute;
          width: 100%;
          text-align: center;
          bottom: 50px;
          z-index: 2;
          &.gold {
            color: rgba($color: $gold-card, $alpha: 0.6);
          }
          &.silver {
            color: rgba($color: $silver, $alpha: 0.6);
          }
          &.platinum {
            color: rgba($color: $platinum, $alpha: 0.6);
          }
          &.palladium {
            color: rgba($color: $palladium, $alpha: 0.6);
          }
        }
        .is-selected {
          height: 60px;
          width: 60px;
          border-radius: 60px;
          border: 5px solid $white;
          background-color: $white;
          margin-left: auto;
          margin-right: auto;
          position: absolute;
          bottom: -30px;
          display: inline-block;
          margin-left: -30px;
          z-index: 3;
          &.selected {
            background-color: $red;
          }
          &.gold {
            background-color: $gold-card;
          }
          &.silver {
            background-color: $silver;
          }
          &.platinum {
            background-color: $platinum;
          }
          &.palladium {
            background-color: $palladium;
          }
        }
      }
    }
  }
  .fineness-selector {
    margin-top: 200px;
    .label {
      font-size: $text-large;
      text-align: center;
      color: $red;
    }
    .list {
      display: flex;
      gap: 10px;
      margin-top: 30px;
      .item {
        flex: 1 1 0px;
        text-align: center;
        cursor: pointer;
        .name {
          text-align: center;
          font-size: $text-medium;
          color: $red;
        }
        .is-selected {
          margin-right: auto;
          margin-right: auto;
          border-radius: 30px;
          height: 30px;
          width: 30px;
          border: 5px solid $white;
          background-color: $white;
          display: inline-block;
          &.selected {
            background-color: $red;
          }
        }
      }
    }
  }
  .mobile-selector {
    display: none;
  }
  .amount-input {
    display: flex;
    gap: 10px;
    margin-top: 75px;
    .current-price,
    .input-wrapper,
    .result {
      width: 33%;
      .label {
        text-align: center;
        color: $red;
        font-size: $text-large;
        margin-bottom: 10px;
      }
      .value {
        font-size: $text-large;
        padding: 3px 5px;
        text-align: center;
        color: $red;
        background-color: $white;
        border-radius: 50px;
        overflow: hidden;
      }
    }
    .input-wrapper {
      input {
        width: 100%;
        text-align: center;
        border: none;
        outline: none;
        color: $red;
        background-color: $white;
      }
    }
  }

  .metal-type {
    width: 100%;
    align-items: center;
    margin: 15px 0px;
    .name {
      width: 20%;
      font-family: 'Stolzl-Bold';
      font-size: $text-large;
    }
    .input-wrapper {
      text-align: center;
      width: 50%;
    }
    .result {
      text-align: right;
      width: 30%;
      .value {
        font-family: 'Stolzl-Bold';
      }
      .hint {
        font-size: $text-small;
      }
    }
  }
  .refreshed-data {
    font-size: $text-small;
    margin-top: 10px;
    color: $red;
  }
  .summary {
    margin-top: 15px;
    .headline {
      color: $red;
      font-size: $text-large;
    }
    .item {
      .variant {
        margin-bottom: 5px;
        display: flex;
        .label,
        .value {
          flex: 1 1 0px;
          color: $red;
        }
        .value {
          text-align: right;
        }
        .remove {
          margin-left: 20px;
          color: $red;
          cursor: pointer;
        }
      }
    }
  }
  @media (max-width: $breakpoint) {
    .type-selector,
    .fineness-selector {
      display: none;
    }
    .mobile-selector {
      display: block;
    }
    .amount-input {
      margin-top: 25px;
      display: block;
      .current-price,
      .input-wrapper,
      .result {
        width: 100%;
        .label {
          margin-bottom: 0px;
          margin-top: 10px;
        }
      }
      .refreshed-data {
        text-align: center;
        margin-top: 0px;
      }
    }
    .summary {
      .item {
        .variant {
          margin: 5px 0px;
          padding: 5px 0px;
          border-top: thin solid $red;
          &:first-child {
            border-top: none;
          }
          display: block;
          .value {
            text-align: left;
          }
          .remove {
            margin-left: 0px;
          }
        }
      }
    }
  }
}
