@import '../../../__variables.scss';

#header {
  padding: 15px;
  position: relative;
  padding-top: 100px;
  .logo {
    font-weight: bolder;
    color: $white;
    text-align: center;
    img {
      height: 175px;
    }
  }
  .purchase-portal-label {
    font-size: 50px;
    color: $gold;
    text-align: center;
    margin-top: 20px;
    font-weight: bold;
    text-transform: uppercase;
  }

  .back-to-main-page {
    top: 100px;
    position: absolute;
    margin-top: 0px;
    margin-left: 50px;
    color: $gold;
    text-align: center;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    a,
    svg * {
      font-size: $text-x-large;
      color: $gold;
      text-decoration: none;
    }
    svg {
      font-size: 50px;
      font-weight: thin;
    }
  }

  @media (max-width: $breakpoint) {
    .purchase-portal-label {
      font-size: 30px;
    }
    .back-to-main-page {
      top: 30px;
      margin-left: 20px;
      svg {
        font-size: 30px;
      }
      a {
        font-size: 20px;
      }
    }
  }
}
