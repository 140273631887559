@import '../../../_variables';

#personal-form {
  margin-top: 75px;
  .headline {
    font-size: $text-large;
    color: $red;
    text-align: center;
  }
  .attributes {
    margin-top: 10px;
    display: flex;
    gap: 15px;
    flex-wrap: wrap;
    .label {
      font-family: 'Stolzl-Bold';
      margin-bottom: 10px;
    }
    margin-bottom: 20px;
    .input-wrapper {
      width: calc(50% - 8px);
      display: flex;
      gap: 5px;
      input {
        width: 100%;
        border-radius: 50px;
        padding: 5px;
        border: none;
        outline: none;
        text-align: center;
        color: $red;
        background-color: $white;
        &::placeholder {
          /* Chrome, Firefox, Opera, Safari 10.1+ */
          color: $red;
          opacity: 1; /* Firefox */
        }

        &:-ms-input-placeholder {
          /* Internet Explorer 10-11 */
          color: $red;
        }

        &::-ms-input-placeholder {
          /* Microsoft Edge */
          color: $red;
        }
      }
    }
  }
  .submit-wrapper {
    text-align: center;
    margin-top: 30px;
    button {
      border-radius: 50px;
      color: $white;
      background-color: $red;
      padding: 5px 30px;
      border: none;
    }
  }
  @media (max-width: $breakpoint) {
    margin-top: 25px;
    .attributes {
      display: block;
      .input-wrapper {
        margin-bottom: 10px;
        width: 100%;
        &:last-child {
          margin-bottom: 0px;
        }
      }
    }
  }
}
